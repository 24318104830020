@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&family=Roboto:wght@100;200;300;400;500;600;700;900&display=swap');
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #EA8231;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #EA8231;
  --ion-color-primary-tint: #EA8231;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-font-family: 'Inter', sans-serif;

  /** primary orange **/
	--ion-color-primary-orange: #EA8231;
	--ion-color-primary-orange-rgb: 234,130,49;
	--ion-color-primary-orange-contrast: #ffffff;
	--ion-color-primary-orange-contrast-rgb: 255,255,255;
	--ion-color-primary-orange-shade: #ce722b;
	--ion-color-primary-orange-tint: #ec8f46;

  /** secondary orange **/
  --ion-color-secondary-orange: #ffffff;
	--ion-color-secondary-orange-rgb: 255,255,255;
	--ion-color-secondary-orange-contrast: #EA8231;
	--ion-color-secondary-orange-contrast-rgb: 234,130,49;
	--ion-color-secondary-orange-shade: #ffe5d2;
	--ion-color-secondary-orange-tint: #ffffff;

  /** primary purple **/
  --ion-color-primary-purple: linear-gradient(267.19deg, #B179F5 -0.42%, #9254DE 57.59%, #7A33D1 100.84%);
	--ion-color-primary-purple-rgb: 146, 84, 222;
	--ion-color-primary-purple-contrast: #ffffff;
	--ion-color-primary-purple-contrast-rgb: 255,255,255;
	--ion-color-primary-purple-shade: #7A33D1;
	--ion-color-primary-purple-tint: #B179F5;

  /** secondary purple **/
  --ion-color-secondary-purple: #ffffff;
	--ion-color-secondary-purple-rgb: 255,255,255;
	--ion-color-secondary-purple-contrast: #9254de;
	--ion-color-secondary-purple-contrast-rgb: 146, 84, 222;
	--ion-color-secondary-purple-shade: #eee0ff;
	--ion-color-secondary-purple-tint: #ffffff;

  /** primary green **/
  --ion-color-primary-green: linear-gradient(267.19deg, #13C4CA -0.42%, #08979C 57.59%, #017C81 100.84%);
	--ion-color-primary-green-rgb: 8, 151, 156;
	--ion-color-primary-green-contrast: #ffffff;
	--ion-color-primary-green-contrast-rgb: 255,255,255;
	--ion-color-primary-green-shade: #017C81;
	--ion-color-primary-green-tint: #13C4CA;

  /** secondary green **/
  --ion-color-secondary-green: #ffffff;;
	--ion-color-secondary-green-rgb: 255,255,255;
	--ion-color-secondary-green-contrast: #08979C;
	--ion-color-secondary-green-contrast-rgb: 8, 151, 156;
	--ion-color-secondary-green-shade: #baeef0;
	--ion-color-secondary-green-tint: #ffffff;

  /** primary red **/
  --ion-color-primary-red: #EA4255;
  --ion-color-primary-red-rgb: 234,66,85;
  --ion-color-primary-red-contrast: #ffffff;
  --ion-color-primary-red-contrast-rgb: 255,255,255;
  --ion-color-primary-red-shade: #ce3a4b;
  --ion-color-primary-red-tint: #ec5566;

  /** secondary red **/
  --ion-color-secondary-red: #ffffff;
	--ion-color-secondary-red-rgb: 255,255,255;
	--ion-color-secondary-red-contrast: #EA4255;
	--ion-color-secondary-red-contrast-rgb: 234,66,85;
	--ion-color-secondary-red-shade: #ffcbd1;
	--ion-color-secondary-red-tint: #ffffff;

  /** primary blue **/
  --ion-color-primary-blue: #23364B;
  --ion-color-primary-blue-rgb: rgb(35 54 75);
  --ion-color-primary-blue-contrast: #ffffff;
  --ion-color-primary-blue-contrast-rgb: 255,255,255;
  --ion-color-primary-blue-shade: #263e5a;
  --ion-color-primary-blue-tint: #36475a;

  /** Grid **/
  --ion-grid-padding: 0;
  --ion-grid-column-padding: 0;

  /** Brya **/
  /** colors **/
  --colors-primary-orange: #ea8231;
  --colors-primary-blue: #23364B;
  --color-deep-blue: #0F2851;
  --color-text: rgba(0, 0, 0, 0.87);
  --colors-golden-purple: #9254DE;
  --sunset-orange-4: #FFC069;
  --White: #ffffff;
  --Base-2: #ffffff;
  --sunset-orange-1: #fff7e6;

  /** gradients **/
  --colors-gradient-green: linear-gradient(267.19deg, #13c4ca -0.42%, #08979c 57.59%, #017c81 100.84%);
  --colors-gradient-green-sheet: linear-gradient(180deg, #dbf9fc 0%, #ffffff 98.45%);
  --colors-gradient-red: var(--interaction-error);
  --colors-gradient-red-sheet: linear-gradient(180deg, #ffe4e2 0%, #ffffff 98.45%);
  --colors-gradient-blue: linear-gradient(267.19deg,#084282 -0.42%,#00356f 41.24%,#00244a 100.84%);
  --colors-gradient-blue-sheet: linear-gradient(180deg, #dbe0fc 0%, #ffffff 98.45%);
  --colors-gradient-light-blue: #3F51B5;
  --colors-gradient-light-blue-sheet: linear-gradient(180deg, #DEE3FF 0%, #FFF 98.45%);
  --colors-gradient-purple: linear-gradient(267.19deg, #B179F5 -0.42%, #9254DE 57.59%, #7A33D1 100.84%);
  --colors-gradient-purple-sheet: linear-gradient(180deg, #F0E3FF 0%, #FFFFFF 98.45%);
  --colors-gradient-orange: linear-gradient(267deg, #FF9B4E -0.42%, #EA8231 57.59%, #E4731B 100.84%);
  --colors-gradient-orange-sheet: linear-gradient(180deg, #FFEDDF 0%, #FFF 98.45%);

  /** typography **/
  --character-title-85: rgba(0, 0, 0, 0.85);
  --body-text-200: #eeeeee;
  --body-text-300: #e0e0e0;
  --body-text-500: #9e9e9e;
  --body-text-600: #757575;
  --body-text-700: #616161;
  --body-text-900: #212121;
  --neutral: #595959;

  /** feedback **/
  --interaction-success: #3A868C;
  --interaction-error: #EA4255;
}

.ion-color-primary-orange {
	--ion-color-base: var(--ion-color-primary-orange);
	--ion-color-base-rgb: var(--ion-color-primary-orange-rgb);
	--ion-color-contrast: var(--ion-color-primary-orange-contrast);
	--ion-color-contrast-rgb: var(--ion-color-primary-orange-contrast-rgb);
	--ion-color-shade: var(--ion-color-primary-orange-shade);
	--ion-color-tint: var(--ion-color-primary-orange-tint);
}

.ion-color-secondary-orange {
	--ion-color-base: var(--ion-color-secondary-orange);
	--ion-color-base-rgb: var(--ion-color-secondary-orange-rgb);
	--ion-color-contrast: var(--ion-color-secondary-orange-contrast);
	--ion-color-contrast-rgb: var(--ion-color-secondary-orange-contrast-rgb);
	--ion-color-shade: var(--ion-color-secondary-orange-shade);
	--ion-color-tint: var(--ion-color-secondary-orange-tint);
}

.ion-color-primary-purple {
	--ion-color-base: var(--ion-color-primary-purple);
	--ion-color-base-rgb: var(--ion-color-primary-purple-rgb);
	--ion-color-contrast: var(--ion-color-primary-purple-contrast);
	--ion-color-contrast-rgb: var(--ion-color-primary-purple-contrast-rgb);
	--ion-color-shade: var(--ion-color-primary-purple-shade);
	--ion-color-tint: var(--ion-color-primary-purple-tint);
}

.ion-color-secondary-purple {
	--ion-color-base: var(--ion-color-secondary-purple);
	--ion-color-base-rgb: var(--ion-color-secondary-purple-rgb);
	--ion-color-contrast: var(--ion-color-secondary-purple-contrast);
	--ion-color-contrast-rgb: var(--ion-color-secondary-purple-contrast-rgb);
	--ion-color-shade: var(--ion-color-secondary-purple-shade);
	--ion-color-tint: var(--ion-color-secondary-purple-tint);
}

.ion-color-primary-green {
	--ion-color-base: var(--ion-color-primary-green);
	--ion-color-base-rgb: var(--ion-color-primary-green-rgb);
	--ion-color-contrast: var(--ion-color-primary-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-primary-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-primary-green-shade);
	--ion-color-tint: var(--ion-color-primary-green-tint);
}

.ion-color-secondary-green {
	--ion-color-base: var(--ion-color-secondary-green);
	--ion-color-base-rgb: var(--ion-color-secondary-green-rgb);
	--ion-color-contrast: var(--ion-color-secondary-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-secondary-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-secondary-green-shade);
	--ion-color-tint: var(--ion-color-secondary-green-tint);
}

.ion-color-primary-red {
	--ion-color-base: var(--ion-color-primary-red);
	--ion-color-base-rgb: var(--ion-color-primary-red-rgb);
	--ion-color-contrast: var(--ion-color-primary-red-contrast);
	--ion-color-contrast-rgb: var(--ion-color-primary-red-contrast-rgb);
	--ion-color-shade: var(--ion-color-primary-red-shade);
	--ion-color-tint: var(--ion-color-primary-red-tint);
}

.ion-color-secondary-red {
	--ion-color-base: var(--ion-color-secondary-red);
	--ion-color-base-rgb: var(--ion-color-secondary-red-rgb);
	--ion-color-contrast: var(--ion-color-secondary-red-contrast);
	--ion-color-contrast-rgb: var(--ion-color-secondary-red-contrast-rgb);
	--ion-color-shade: var(--ion-color-secondary-red-shade);
	--ion-color-tint: var(--ion-color-secondary-red-tint);
}

.ion-color-primary-blue {
  --ion-color-base: var(--ion-color-primary-blue);
  --ion-color-base-rgb: var(--ion-color-primary-blue-rgb);
  --ion-color-contrast: var(--ion-color-primary-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-blue-shade);
  --ion-color-tint: var(--ion-color-primary-blue-tint);
}
