html,
body {
  font-family: 'Inter';
}

ul, li {
  margin: 0;
}
.swiper-pagination-bullet-active {
  background: #ea8231 !important;
}

ion-tabs {
  contain: none;
}

ion-tab-bar {
  height: 76px;
  position: relative;
  --background: #fff;
  contain: none;
}

ion-tab-button {
  --color: #23364b;
  --color-selected: #ea8231;
  --background: #fff;
}

ion-tab-button::part(native) {
  overflow: visible;
}

h1,
h2 {
  margin: 0;
}
.time-slot-select-option {
  font-size: 12px !important;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.408px;
}
.activity-options {
  font-size: 16px !important;
  letter-spacing: -0.408px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.408px;
  min-width: 290px !important;
}

#services-footer-toolbar {
  --background: #fff;
}

.custom-time-modal {
  --height: auto;
}

ion-datetime {
  --background: #ffffff;
}
ion-select::part(placeholder) {
  opacity: 1;
}

/* CoachProfileHeader.tsx */
.header-container-svg {
  width: 103%;
  max-width: 900px; /* You can adjust this value if needed */
  margin: 0 auto; /* To center the SVG horizontally */
  margin-left: -4px;
  margin-top: -24px;
  height: 220px;
  @media only screen and (max-width: 360px) {
    height: 200px;
    margin-top: -32px;
  }
  @media only screen and (max-width: 320px) {
    height: 180px;
    margin-top: -40px;
  }
}

/* Optionally, you can make the SVG responsive by setting its height to auto */
.header-container-svg svg {
  width: 100%;
  height: auto;
}

.coach-toolbar {
  height: 180px;
  @media only screen and (max-width: 360px) {
    height: 161px;
  }
  @media only screen and (max-width: 320px) {
    height: 153px;
  }
}
ion-label {
  min-width: fit-content;
}

.custom-ion-margin-32 {
  --ion-margin: 32px;
}
